import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const PortfolioItem = ({ title, description, subtitle, image, url, t }) => {
  let imageUrl =
    `https://aolpmeoxvo.cloudimg.io/v7/${ image.replace(/(^\w+:|^)\/\//, '') }?h=200&w=320`
  return (
    <div className="light-shadow card text-white bg-dark border-0 rounded">
      <LazyLoadImage src={ imageUrl } alt={ title }
        className="card-img card-image-fixed" />
      <div className="card-img-overlay img-overlay">
        <h5 className="card-title">{ title }</h5>
        <nav className="level card-text">
          <div className="level-left">
            <div className="tags has-addons">
              <span className="tag is-primary">{ description }</span>
            </div>
          </div>
          <div className="level-right">
            <a target="_blank" className="portfolio-link"
              href={ url }>{t('read-it')}</a>
          </div>
        </nav>
      </div>
    </div>
  )
}

PortfolioItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string
}

export default translate()(PortfolioItem)
