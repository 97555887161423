import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/service-components/seo'
import PortfolioItem from '../components/portfolio-item'
import { graphql } from 'gatsby'

class Portfolio extends React.Component {
  constructor (props) {
    super(props)
    const { data } = this.props
    let airtableData = data.allAirtable.edges
    this.state = {
      items: this.createPortfolioItems(airtableData)
    }
  }

  createPortfolioItems = data => Object.values(data).map(item => {
    let itemData = item.node.data
    return Object.assign({}, {
      title: itemData.ArticleName,
      image: itemData.ImageUrl,
      url: itemData.ArticleLink,
      createdAt: new Date(),
      key: itemData.ArticleLink + new Date()
    })
  }
  ).reverse()

  render () {
    return (
      <Layout>
        <SEO title="Portfolio"/>
        <div className="container-fluid">
          <div className="row">
            { (this.state.items.map(p =>
              <div key={ p.key }
                className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-4">
                <PortfolioItem title={ p.title }
                  description={ '' } image={ p.image } url={ p.url }/>
              </div>))
            }
          </div>
        </div>
      </Layout>
    )
  }
}

export default Portfolio

export const query = graphql`
{
    allAirtable(filter: { table: { eq: "Portfolio" } }) {
      edges {
        node {
          data {
            ArticleName
            ImageUrl
            ArticleLink
          }
        }
      }
    }
  }
`
